import React from 'react';
import { Link } from 'gatsby';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import promlensLogo from '../images/promlens-logo-with-text.svg';

const Header = () => (
  <Navbar variant="light" expand="md">
    <Container fluid="xl">
      <Navbar.Brand as={Link} to="/" href="/" className="ml-1">
        <img src={promlensLogo} height="30" className="d-inline-block align-top" alt="PromLens logo"></img>
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Nav className="ml-auto">
          <Nav.Link href="https://demo.promlens.com/">Demo</Nav.Link>
          <Nav.Link href="https://github.com/prometheus/promlens">Deploy</Nav.Link>
          {/* <Nav.Link as={Link} to="/pricing" href="/pricing">
            Pricing
          </Nav.Link> */}
          <NavDropdown title="Resources" id="resources-dropdown" alignRight>
            <NavDropdown.Item href="https://promlabs.com/promql-cheat-sheet">PromQL Cheat Sheet</NavDropdown.Item>
            <NavDropdown.Item href="https://promlabs.com/">Prometheus Services</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);

export default Header;
