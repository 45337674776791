/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';

import Header from './header';
import 'bootstrap/dist/css/bootstrap.min.css';
import './layout.css';
import { Container, Row, Col } from 'react-bootstrap';
import { FaTwitter } from 'react-icons/fa';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header />
      <main>{children}</main>
      <footer className="footer">
        <Container fluid className="text-muted">
          <Row>
            <Col md={4} className="text-center my-2">
              <div className="ml-2">
                &copy; {new Date().getFullYear()} <a href="https://promlabs.com/">PromLabs GmbH</a>
              </div>
            </Col>
            <Col md={4} className="text-center my-2">
              <a href="https://twitter.com/promlabs">
                <FaTwitter className="mr-2" /> Follow us on Twitter
              </a>
            </Col>
            <Col md={4} className="text-center my-2">
              <Link className="ml-4 mr-3" to="/privacy">
                Privacy
              </Link>
              <Link className="ml-4 mr-3" to="/imprint">
                Imprint
              </Link>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
